<template>
  <v-container class="profile-update-container scroll-container">
    <div id="container_gy"></div>
    <v-btn
      large
      depressed
      outlined
      color="primary"
      class="rounded-pill go-to-dashboard-btn my-3"
      :to="{ name: 'Dashboard' }"
    >
      {{ $t("profile.fidelity.goToDashboard") }}
    </v-btn>
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
}
</style>
<script>
import { showToaster } from "@/commons/notifications";
import { mapGetters } from "vuex";

export default {
  name: "ProfileUpdate",
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  async mounted() {
    let vm = this;
    const script = document.createElement("script");
    script.src = `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${process.env.VUE_APP_GIGYA_API_KEY}&lang=it`;

    script.async = true;
    const successMsg = this.$t("profile.updateSuccessMessage");
    script.onload = function() {
      // eslint-disable-next-line no-undef
      this.gigya = gigya;

      var _this = this;

      if (vm.profile) {
        console.log("USER PASSED!: " + vm.profile);
      }

      function GigyaLoadFunctions() {
        console.log("Loading Gigya screen set...");
        _this.gigya.accounts.showScreenSet({
          containerID: "container_gy",
          screenSet: "Iperdrive-ProfileUpdate",
          startScreen: vm?.user?.fidelityCard?.trim()
            ? "gigya-update-profile-card-owner-screen"
            : "gigya-update-profile-screen",
          onAfterScreenLoad: function(event) {
            console.log("Screen loaded:", event);
          },
          onAfterSubmit: function(eventObject) {
            if (eventObject.response.errorCode === 0) {
              showToaster(
                "success",
                { message: successMsg },
                {
                  position: "top-center",
                  duration: 3000,
                  iconPack: "mdi",
                  theme: "bubble",
                  keepOnHover: true,
                  top: true,
                  action: {
                    icon: "$clear"
                  }
                }
              );
              window.scroll({ top: 0, behavior: "smooth" });
            }
          },
          onError: function(eventObject) {
            console.log("ERROR:", eventObject);
            if (eventObject.errorCode == 400009) {
              var customMessage =
                eventObject.response.info.response.validationErrors[0];
              var message_new = "";
              if (customMessage.fieldName == "data") {
                var messageTemp = customMessage.customMessage;
                var code_start = messageTemp.indexOf("SF Response Code :");
                var code_end = messageTemp.indexOf("SF Response Message :");
                if (code_start !== -1) {
                  message_new = messageTemp.substring(code_end + 21).trim();
                } else {
                  code_start = messageTemp.indexOf("Codice SF :");
                  code_end = messageTemp.indexOf("Messaggio SF :");
                  if (code_start !== -1) {
                    message_new = messageTemp.substring(code_end + 14).trim();
                  }
                }
                if (message_new != "") {
                  message_new = message_new.replaceAll(
                    /\[([^\]]+)\]/g,
                    '<a href="$1">$1</a>'
                  );
                  var message_div = document.getElementsByClassName(
                    "gigya-error-msg gigya-form-error-msg gigya-error-code-400009 gigya-error-msg-active"
                  );

                  setTimeout(() => {
                    Array.prototype.forEach.call(message_div, function(div) {
                      div.innerHTML = message_new;
                    });
                  }, 1000);
                }
              }
            }
          }
        });
      }

      // function interceptGigyaAPICalls() {
      //   const originalSetAccountInfo = _this.gigya.accounts.setAccountInfo;

      //   _this.gigya.accounts.setAccountInfo = function(params) {
      //     const originalCallback = params.callback;

      //     params.callback = function(response) {
      //       if (response.errorCode === 0) {
      //         console.log("SetAccountInfo SUCCESS:", response);
      //       }
      //       if (typeof originalCallback === "function") {
      //         originalCallback(response);
      //       }
      //     };

      //     return originalSetAccountInfo.call(_this.gigya.accounts, params);
      //   };
      // }

      // interceptGigyaAPICalls();
      GigyaLoadFunctions();
    };
    document.head.appendChild(script);
  }
};
</script>
